/*Functions*/

/*Scroll top*/
function showScrollTop() {
    if ($(this).scrollTop() > 100) {
        $("#to_top").addClass('active');
    }
    else {
        $("#to_top").removeClass('active');
    }
}

function fixedSidebar(clear){
    if(clear = 1){
        $('#sidebar, aside .sidebar_wrap').attr('style','');
    }

    var parentElement = $('#sidebar');
    var stickyElement = $('aside .sidebar_wrap');
    var container = $('.flex_parent');
    var header = $('header');
    var containerHeight = container.height() + 'px';
    var mediaBreakPoint = '(max-width: 1024px)';
    var height = stickyElement.height();
    var heightString = height + 'px';
    var widthString = stickyElement.width() + 'px';
    var headerHeight = header.height() + 'px';
    function SidebarPosition() {
        var scroll = $(this).scrollTop();

        setParentOrContainerHeight();
        setStickyElementScrollDefaultStyles();

        var length = parentElement.height() - stickyElement.height() + parentElement.offset().top;

        if (!window.matchMedia(mediaBreakPoint).matches) {
            if (scroll < parentElement.offset().top) {
                stickyElement.css({
                    'position': 'absolute',
                    'top': '0',
                    'bottom': 'auto'
                });
            }
            else if (scroll > length) {
                stickyElement.css({
                    'position': 'absolute',
                    'top': 'auto',
                    'bottom': '0'
                });
            }
            else {
                stickyElement.css({
                    'position': 'fixed',
                    'top': '20px',
                    'bottom': 'auto'
                });
            }
        }
        else {
            setStickyElementMobileDefaultStyles();
        }
    }
    function scrollStickySidebar() {
        $(window).scroll(function () {
            SidebarPosition();
        });
    }

    SidebarPosition();
    function setStickyElementScrollDefaultStyles() {
        stickyElement.css({
            'height': heightString,
            'width': widthString,
            'max-width': widthString
        });
    }

    function setStickyElementMobileDefaultStyles() {
        setParentToAutoHeight();

        stickyElement.css({
            'position': 'relative',
            'top': 'auto',
            'bottom': 'auto',
            'height': 'auto',
            'width': '100%'
        });
    }

    function setParentOrContainerHeight() {
        if (height < container.height()) {
            parentElement.css({
                'height': containerHeight
            });
        }
        else {
            parentElement.css({
                'height': heightString
            });
            container.css({
                'height': heightString
            });
        }
    }

    function setParentToAutoHeight() {
        parentElement.css({
            'height': 'auto'
        });
    }

    scrollStickySidebar();

    $(window).resize(scrollStickySidebar);
}

$(document).ready(function () {
    "use strict";
    var siteBody = $("body");
    /*Init*/
    siteBody.addClass(currentBrowser()).addClass(detectmob());
    showScrollTop();
    $(window).scroll(function () {
        showScrollTop();
    });
    /*Events*/
    // $(window).on('load', function () {
    //
    // });

    if ($("#sidebar")[0] && $(window).width() > 1024){
        $(window).on('load', function () {
            fixedSidebar();
        });
    }
    $(window).bind('resize', function () {
        if ($("#sidebar")[0] && $(window).width() > 1024){
            $(window).on('load', function () {
                fixedSidebar();
            });
        }
    });

    /*Scroll top Click*/
    $("#to_top").on("click","a", function (event) {
        event.preventDefault();
        var id  = $(this).attr('href'),
            top = $(id).offset().top;
        $('html, body').animate({scrollTop: top}, 1000);
    });
    /*Menu*/
    $("#menuOpen button.hamburger").on('click',function (e) {
        $('#mainMenu').toggleClass("opened");
        $(this).parent().toggleClass("opened");
        $(this).toggleClass('is-active');
        $('body').toggleClass('hidden');
    });
    $('#mainMenu li.menu-item-has-children').append('<span class="subMenuButton i-angle-down"></span>');
    $('.subMenuButton').on('click',function () {
        var t = $(this);
        if(t.hasClass('open')) {
            t.removeClass('open').prev().slideUp(300);
        } else {
            t.removeClass('open').prev().slideDown(300);
            t.addClass('open');
        }
    });
    //WPCF7
    $(this).on('click', '.wpcf7-not-valid-tip', function () {
        $(this).prev().trigger('focus');
        $(this).fadeOut(500, function () {
            $(this).remove();
        });
    });
    //Privacy
    if(!getCookie('privacy')){
        var privacy = $('#privacy');
        privacy.show();
        $('body').css('padding-bottom',privacy.innerHeight());
        $('#to_top a').css('bottom',privacy.innerHeight() + 15);
    }
    $('#privacy .privacy_close').on('click',function () {
        $('#privacy').addClass('hide');
        $('body').css('padding-bottom',0);
        $('#to_top a').css('bottom','15px');
        setCookie('privacy','true',1);
    });

    /**
     * Top slider
     */
    var swiper = new Swiper('.top_block_slider .swiper-container', {
        loop: true,
        slidesPerView: 2,
        spaceBetween: 83,
        speed: 2000,
        autoplay: {
            delay: 4000,
            disableOnInteraction: false
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        breakpoints: {
            767: {
                slidesPerView: 1
            }
        }
    });

    /**
     * First order slider
     */
    var swiper2 = new Swiper('.latest_order_slider .swiper-container', {
        loop: true,
        slidesPerView: 3,
        spaceBetween: 56,
        speed: 2000,
        autoplay: {
            delay: 2000,
            disableOnInteraction: false
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
        breakpoints: {
            1024: {
                slidesPerView: 2
            },
            767: {
                slidesPerView: 1
            }
        }
    });

    /**
     * Second order slider
     */
    var swiper3 = new Swiper('.latest_order_slider2 .swiper-container', {
        loop: true,
        slidesPerView: 2,
        spaceBetween: 60,
        speed: 2000,
        autoplay: {
            delay: 2000,
            disableOnInteraction: false
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
        breakpoints: {
            1024: {
                slidesPerView: 1
            }
        }
    });

    /**
     * Stop slider on hover
     */
    $('.top_block_slider .swiper-container').hover(function(){
            swiper.autoplay.stop();
        },
        function(){
            swiper.autoplay.start();
        }
    );
    $('.latest_order_slider .swiper-container').hover(function(){
            swiper2.autoplay.stop();
        },
        function(){
            swiper2.autoplay.start();
        }
    );

    $('.latest_order_slider2 .swiper-container').hover(function(){
            swiper3.autoplay.stop();
        },
        function(){
            swiper3.autoplay.start();
        }
    );

    /**
     * More content button
     */
    $('.content_more_block .content_more_full').css('display', 'none');
    $('.content_more_block .content_more_btn').click(function () {
        if($(this).hasClass('active')) {
            $(this).prev().show(500);
            $(this).removeClass('active');
            $(this).html('Read less &#8594;');
        } else {
            $(this).prev().hide(500);
            $(this).addClass('active');
            $(this).html('Read more &#8594;');
        }
    });

    if($('.countdown').hasClass('active')){
        $('.stat-number').each(function () {
            var count = $(this);
            $({countNum: count.text()}).animate({
                countNum: count.attr('data-count')
            }, {
                duration: 1500,
                easing: 'linear',
                step: function () {
                    count.text(Math.floor(this.countNum));
                },
                complete: function () {
                    count.text(this.countNum);
                }
            });
        });
    }

    /**
     * Accordeon
     * @type {*|number|BigInt|T}
     */
    var acc_var = $('.faq').find('.acc_title');
    acc_var.click(function() {

        if($(this).hasClass('opened')){
            acc_var.removeClass('opened');
            $(this).next().slideToggle(300).parent().removeClass('open');
            $('.faq').find('.info').slideUp(300).parent().removeClass('open');
        }
        else {
            $('.faq').find('.info').slideUp(300).parent().removeClass('open');
            $(this).next().slideToggle(300).parent().addClass('open');
            acc_var.removeClass('opened');
            $(this).addClass('opened');
        }
    });
    acc_var.eq(2).click();

    /**
     * For form effect.
     */
    formLabel();

    /**
     * For submitting form on enter
     */
    $('.order_form input').keypress(function (e) {
        if (e.which == 13) {
            $('form.order_form').submit();
            return false;    //<---- Add this line
        }
    });
});


/**
 * For form effect.
 */
function formLabel(){
    $('.order_form input').each(function(){
        var $theTxt = $(this);
        var $theLabel = $theTxt.siblings('label');
        $theTxt.blur();
        if($theTxt.val()=='') {
            $theLabel.css({'font-size':'14px','left':'10px','top':'10px','transition':'0.3s ease-out'});
        } else {
            $theLabel.css({'font-size':'12px','left':'0','top':'-20px','transition':'0.3s ease-out'});
        }
        $theLabel.click(function(){
            $theLabel.css({'font-size':'12px','left':'0','top':'-20px','transition':'0.3s ease-out'}); $theTxt.focus();
        });
        $theTxt.focus(function(){
            if($theTxt.val()==''){ $theLabel.css({'font-size':'12px','left':'0','top':'-20px','transition':'0.3s ease-out'}); }
        });
        $theTxt.blur(function(){
            if($theTxt.val()==''){ $theLabel.css({'font-size':'14px','left':'10px','top':'10px','transition':'0.3s ease-out'}); }
        });
    });
}




/**
 * GA
 */
// $('.login_btn').click(function(){
//     ga('send', 'event', 'CTA', 'Click', 'Login');
// });

// $('.get_started_1').click(function(){
//     ga('send', 'event', 'CTA', 'Click', 'Get Started first screen');
// });

// $('.get_started_2').click(function(){
//     ga('send', 'event', 'CTA', 'Click', 'Get Started main requirements');
// });
//
// $('.get_started_3').click(function(){
//     ga('send', 'event', 'CTA', 'Click', 'Get Started evolve with us');
// });
//
// $('.start_registration_btn').click(function(){
//     ga('send', 'event', 'CTA', 'Click', 'Start registration');
// });

// $('[data-login]').on("click", function(e) {
//     if(typeof ga !== 'undefined'){
//         ga('send', 'event', 'CTA', 'Click', 'Login');
//     }
//     window.location.href = 'https://writer.writingcreek.com/?login-first=1';
// });

$('[data-get-started1]').on("click", function(e) {
    if(typeof ga !== 'undefined'){
        ga('send', 'event', 'CTA', 'Click', 'Get Started first screen');
    }
    // window.location.href = 'https://writer.writingcreek.com/?login-first=1&login-fast-signup-writer=1';
});

$('[data-get-started2]').on("click", function(e) {
    if(typeof ga !== 'undefined'){
        ga('send', 'event', 'CTA', 'Click', 'Get Started main requirements');
    }
    // window.location.href = 'https://writer.writingcreek.com/?login-first=1&login-fast-signup-writer=1';
});

$('[data-get-started3]').on("click", function(e) {
    if(typeof ga !== 'undefined'){
        ga('send', 'event', 'CTA', 'Click', 'Get Started evolve with us');
    }
    // window.location.href = 'https://writer.writingcreek.com/?login-first=1&login-fast-signup-writer=1';
});

$('[data-get-started4]').on("click", function(e) {
    if(typeof ga !== 'undefined'){
        ga('send', 'event', 'CTA', 'Click', 'Start registration');
    }
    // window.location.href = 'https://writer.writingcreek.com/?login-first=1';
});


/**
 * Blog form
 */

/**
 * Scroll effect
 * @param item
 */
function scrollToElement(item) {
    if(!$('div, ul').is('.' + item)){
        return false;
    }

    var hT = $('.' + item).offset().top,
        hH = $('.' + item).outerHeight(true),
        wH = $(window).height(),
        wS = $(this).scrollTop();
    if (wS > (hT + hH - wH - hH)) {
        $('.' + item).addClass('active');
    }
}

scrollToElement('countdown-effect');
scrollToElement('line-effect');
scrollToElement('requirements-effect');
scrollToElement('believe-effect');
scrollToElement('map_svg_block');

$(window).scroll(function () {
    scrollToElement('countdown-effect');
    scrollToElement('line-effect');
    scrollToElement('requirements-effect');
    scrollToElement('believe-effect');
    scrollToElement('map_svg_block');
});


(function(){
    // Your base, I'm in it!
    var originalAddClassMethod = jQuery.fn.addClass;

    jQuery.fn.addClass = function(){
        // Execute the original method.
        var result = originalAddClassMethod.apply( this, arguments );

        // trigger a custom event
        jQuery(this).trigger('cssClassChanged');

        // return the original result
        return result;
    }
})();

if($('div, ul').is('.countdown')){
    $(".countdown").bind('cssClassChanged', function(){
        $('.stat-number').each(function () {
            var count = $(this);
            $({countNum: count.text()}).animate({
                countNum: count.attr('data-count')
            }, {
                duration: 1500,
                easing: 'linear',
                step: function () {
                    count.text(Math.floor(this.countNum));
                },
                complete: function () {
                    count.text(this.countNum);
                }
            });
        });
    });
}

/**
 * Round chart initialization
 */
window.onload = function () {
    if($('div').is('#chartContainer')){
        CanvasJS.addColorSet("ownColor",
            [
                "#4cc9ff",
                "#f6e61c",
                "#fe6478",
                "#20d0c2",
                "#bb64fe",
                "#ff980d"
            ]);
        var chart = new CanvasJS.Chart("chartContainer", {
            animationEnabled: true,
            colorSet: "ownColor",
            title:{
                horizontalAlign: "left"
            },
            data: [{
                type: "doughnut",
                startAngle: -40,
                innerRadius: 90,
                indexLabelFontSize: 14,
                indexLabel: "#percent%",
                toolTipContent: "<strong>{label}:</strong>(#percent%)",
                dataPoints: [
                    { y: 16, label: "Humanities"},
                    { y: 22, label: "Applied sciences" },
                    { y: 18, label: "Social sciences" },
                    { y: 30, label: "Formal sciences"},
                    { y: 4, label: "Natural sciences"},
                    { y: 10, label: "Other academic fields", exploded: true}
                ]
            }]
        });
        chart.render();
    }
};




$(document).ready(function() {

    function getQueryParams() {
        const params = {};
        const queryString = window.location.search.substring(1);
        const regex = /([^&=]+)=([^&]*)/g;
        let match;

        while (match = regex.exec(queryString)) {
            params[decodeURIComponent(match[1])] = decodeURIComponent(match[2]);
        }

        return params;
    }

    function setCookie(name, value, days) {
        const expires = new Date(Date.now() + days * 864e5).toUTCString();
        document.cookie = name + '=' + encodeURIComponent(value) + '; expires=' + expires + '; path=/';
    }

    function getCookie(name) {
        return document.cookie.split('; ').reduce((r, v) => {
            const parts = v.split('=');
            return parts[0] === name ? decodeURIComponent(parts[1]) : r;
        }, '');
    }

    function saveQueryParamsToCookie() {
        const cookieName = 'queryParams';
        const referrer = document.referrer;
        const currentDomain = window.location.hostname;
        const shouldUpdateCookie = !referrer || !referrer.includes(currentDomain);

        if (shouldUpdateCookie) {
            const params = getQueryParams();
            const paramsString = JSON.stringify(params);
            setCookie(cookieName, paramsString, 7); // Зберігаємо кукі на 7 днів
        }
    }

    saveQueryParamsToCookie();
    window.addEventListener('load', () => {
        let cookieValue = getCookie('queryParams');
        if (cookieValue) {
            let queryParams = JSON.parse(cookieValue);
            let queryString = new URLSearchParams(queryParams).toString();

            // Отримати всі елементи з класом .header-order-email
            let elements = document.querySelectorAll('.header-order-email');

            // Оновити атрибут для кожного з елементів
            if (queryString) {
                elements.forEach(element => {
                    element.setAttribute('data-aster-wr-utm', '?' + queryString);
                });
            }
        }
    });

    //window.addEventListener('load', addQueryParamsToLinks);
    $('.login_btn').click( function() {
        const paramsString = getCookie('queryParams');

        const params = JSON.parse(paramsString);
        const queryString = Object.keys(params).map(key => key + '=' + encodeURIComponent(params[key])).join('&');

        const url = new URL("https://writer.writingcreek.com/?login-first=1");
        if (queryString) {
            url.search += '&' + queryString;
        }
        window.location.href = url.toString();
    });

    $('.open_popup').click( function(){
        $('#myPopupOverlay').fadeIn(400,
            function(){
                $('#myPopup')
                    .css('display', 'flex')
                    .animate({opacity: 1, top: '0'}, 200);
            });
    });
    $('.popup-header .close').click( function(){
        $('#myPopup')
            .animate({opacity: 0, top: '45%'}, 200,
                function(){
                    $(this).css('display', 'none');
                    $('#myPopupOverlay').fadeOut(400);
                }
            );
    });

    $(document).mouseup(function (e){
        var div = $("#myPopup .popup-content");
        var btn = $(".open_popup");
        if (!div.is(e.target) && !btn.is(e.target)
            && div.has(e.target).length === 0) {
            $('#myPopup')
                .animate({opacity: 0, top: '45%'}, 200,
                    function(){
                        $(this).css('display', 'none');
                        $('#myPopupOverlay').fadeOut(400);
                    }
                );
        }
    });
});
if (typeof ajaxurl !== 'undefined') {
    setTimeout(function(){
        var data = {
            'action': 'loadmore'
        };
        $.ajax({
            url:ajaxurl,
            data:data,
            type:'POST',
            success:function(data){
                $('.map_svg_block').html(data);
                $('.map_svg_block').css('height', $('.map_svg_block svg').height() + 80);
            }
        });
    }, 1);
}
